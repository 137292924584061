import { useRef } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes";

export default function IdleTimerContainer() {
    const navigate = useNavigate();

    // Функция для обработки события бездействия
    const onIdle = () => {
        navigate(ROUTES.HOME);
    };

    // Использование useIdleTimer
    useIdleTimer({
        crossTab: true, // Если вы работаете с несколькими вкладками
        timeout: 60 * 1000, // Тайм-аут бездействия (60 секунд)
        onIdle: onIdle, // Обработчик события
    });

    // Здесь мы не передаем idleTimer в DOM
    return <div className="idle-timer-container"></div>;
}