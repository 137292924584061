import React, { useEffect, useState } from "react";
import { useAppDispatch } from "hook";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ROUTES } from "constants/routes";
import Game from "./pages/Game";
import Main from "./pages/Main";
import Login from "./pages/Login";
import { setAlert } from "store/mainSlice";

const imagePaths = [
    "/images/1/puzzle_3x2/1.jpeg",
    "/images/1/puzzle_3x2/2.jpeg",
    "/images/1/puzzle_3x2/3.jpeg",
    "/images/1/puzzle_3x2/4.jpeg",
    "/images/1/puzzle_3x2/5.jpeg",
    "/images/1/puzzle_3x2/6.jpeg",
    "/images/1/puzzle_3x2/full.jpeg",

    "/images/1/puzzle_3x3/1.jpeg",
    "/images/1/puzzle_3x3/2.jpeg",
    "/images/1/puzzle_3x3/3.jpeg",
    "/images/1/puzzle_3x3/4.jpeg",
    "/images/1/puzzle_3x3/5.jpeg",
    "/images/1/puzzle_3x3/6.jpeg",
    "/images/1/puzzle_3x3/7.jpeg",
    "/images/1/puzzle_3x3/8.jpeg",
    "/images/1/puzzle_3x3/9.jpeg",
    "/images/1/puzzle_3x3/full.jpeg",

    "/images/2/puzzle_3x2/1.jpeg",
    "/images/2/puzzle_3x2/2.jpeg",
    "/images/2/puzzle_3x2/3.jpeg",
    "/images/2/puzzle_3x2/4.jpeg",
    "/images/2/puzzle_3x2/5.jpeg",
    "/images/2/puzzle_3x2/6.jpeg",
    "/images/2/puzzle_3x2/full.jpeg",

    "/images/2/puzzle_3x3/1.jpeg",
    "/images/2/puzzle_3x3/2.jpeg",
    "/images/2/puzzle_3x3/3.jpeg",
    "/images/2/puzzle_3x3/4.jpeg",
    "/images/2/puzzle_3x3/5.jpeg",
    "/images/2/puzzle_3x3/6.jpeg",
    "/images/2/puzzle_3x3/7.jpeg",
    "/images/2/puzzle_3x3/8.jpeg",
    "/images/2/puzzle_3x3/9.jpeg",
    "/images/2/puzzle_3x3/full.jpeg",

    "/images/3/puzzle_3x2/1.jpeg",
    "/images/3/puzzle_3x2/2.jpeg",
    "/images/3/puzzle_3x2/3.jpeg",
    "/images/3/puzzle_3x2/4.jpeg",
    "/images/3/puzzle_3x2/5.jpeg",
    "/images/3/puzzle_3x2/6.jpeg",
    "/images/3/puzzle_3x2/full.jpeg",

    "/images/3/puzzle_3x3/1.jpeg",
    "/images/3/puzzle_3x3/2.jpeg",
    "/images/3/puzzle_3x3/3.jpeg",
    "/images/3/puzzle_3x3/4.jpeg",
    "/images/3/puzzle_3x3/5.jpeg",
    "/images/3/puzzle_3x3/6.jpeg",
    "/images/3/puzzle_3x3/7.jpeg",
    "/images/3/puzzle_3x3/8.jpeg",
    "/images/3/puzzle_3x3/9.jpeg",
    "/images/3/puzzle_3x3/full.jpeg",

    "/images/4/puzzle_3x2/1.jpeg",
    "/images/4/puzzle_3x2/2.jpeg",
    "/images/4/puzzle_3x2/3.jpeg",
    "/images/4/puzzle_3x2/4.jpeg",
    "/images/4/puzzle_3x2/5.jpeg",
    "/images/4/puzzle_3x2/6.jpeg",
    "/images/4/puzzle_3x2/full.jpeg",

    "/images/4/puzzle_3x3/1.jpeg",
    "/images/4/puzzle_3x3/2.jpeg",
    "/images/4/puzzle_3x3/3.jpeg",
    "/images/4/puzzle_3x3/4.jpeg",
    "/images/4/puzzle_3x3/5.jpeg",
    "/images/4/puzzle_3x3/6.jpeg",
    "/images/4/puzzle_3x3/7.jpeg",
    "/images/4/puzzle_3x3/8.jpeg",
    "/images/4/puzzle_3x3/9.jpeg",
    "/images/4/puzzle_3x3/full.jpeg",

    "/images/5/puzzle_3x2/1.jpeg",
    "/images/5/puzzle_3x2/2.jpeg",
    "/images/5/puzzle_3x2/3.jpeg",
    "/images/5/puzzle_3x2/4.jpeg",
    "/images/5/puzzle_3x2/5.jpeg",
    "/images/5/puzzle_3x2/6.jpeg",
    "/images/5/puzzle_3x2/full.jpeg",

    "/images/5/puzzle_3x3/1.jpeg",
    "/images/5/puzzle_3x3/2.jpeg",
    "/images/5/puzzle_3x3/3.jpeg",
    "/images/5/puzzle_3x3/4.jpeg",
    "/images/5/puzzle_3x3/5.jpeg",
    "/images/5/puzzle_3x3/6.jpeg",
    "/images/5/puzzle_3x3/7.jpeg",
    "/images/5/puzzle_3x3/8.jpeg",
    "/images/5/puzzle_3x3/9.jpeg",
    "/images/5/puzzle_3x3/full.jpeg",

    "/images/6/puzzle_3x2/1.jpeg",
    "/images/6/puzzle_3x2/2.jpeg",
    "/images/6/puzzle_3x2/3.jpeg",
    "/images/6/puzzle_3x2/4.jpeg",
    "/images/6/puzzle_3x2/5.jpeg",
    "/images/6/puzzle_3x2/6.jpeg",
    "/images/6/puzzle_3x2/full.jpeg",

    "/images/6/puzzle_3x3/1.jpeg",
    "/images/6/puzzle_3x3/2.jpeg",
    "/images/6/puzzle_3x3/3.jpeg",
    "/images/6/puzzle_3x3/4.jpeg",
    "/images/6/puzzle_3x3/5.jpeg",
    "/images/6/puzzle_3x3/6.jpeg",
    "/images/6/puzzle_3x3/7.jpeg",
    "/images/6/puzzle_3x3/8.jpeg",
    "/images/6/puzzle_3x3/9.jpeg",
    "/images/6/puzzle_3x3/full.jpeg",

    "/images/7/puzzle_3x2/1.jpeg",
    "/images/7/puzzle_3x2/2.jpeg",
    "/images/7/puzzle_3x2/3.jpeg",
    "/images/7/puzzle_3x2/4.jpeg",
    "/images/7/puzzle_3x2/5.jpeg",
    "/images/7/puzzle_3x2/6.jpeg",
    "/images/7/puzzle_3x2/full.jpeg",

    "/images/7/puzzle_3x3/1.jpeg",
    "/images/7/puzzle_3x3/2.jpeg",
    "/images/7/puzzle_3x3/3.jpeg",
    "/images/7/puzzle_3x3/4.jpeg",
    "/images/7/puzzle_3x3/5.jpeg",
    "/images/7/puzzle_3x3/6.jpeg",
    "/images/7/puzzle_3x3/7.jpeg",
    "/images/7/puzzle_3x3/8.jpeg",
    "/images/7/puzzle_3x3/9.jpeg",
    "/images/7/puzzle_3x3/full.jpeg",

    "/images/8/puzzle_3x2/1.jpeg",
    "/images/8/puzzle_3x2/2.jpeg",
    "/images/8/puzzle_3x2/3.jpeg",
    "/images/8/puzzle_3x2/4.jpeg",
    "/images/8/puzzle_3x2/5.jpeg",
    "/images/8/puzzle_3x2/6.jpeg",
    "/images/8/puzzle_3x2/full.jpeg",

    "/images/8/puzzle_3x3/1.jpeg",
    "/images/8/puzzle_3x3/2.jpeg",
    "/images/8/puzzle_3x3/3.jpeg",
    "/images/8/puzzle_3x3/4.jpeg",
    "/images/8/puzzle_3x3/5.jpeg",
    "/images/8/puzzle_3x3/6.jpeg",
    "/images/8/puzzle_3x3/7.jpeg",
    "/images/8/puzzle_3x3/8.jpeg",
    "/images/8/puzzle_3x3/9.jpeg",
    "/images/8/puzzle_3x3/full.jpeg",
];

const preloadImages = (images: string[]) => {
    return Promise.all(
        images.map((src) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = src;
                img.onload = resolve;
                img.onerror = reject;
            });
        })
    );
};

function App() {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        preloadImages(imagePaths)
            .then(() => {
                console.log("Изображения успешно предзагружены");
            })
            .catch((error) => {
                console.error("Ошибка при предзагрузке изображений:", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        const handleOnline = () => {
            dispatch(setAlert({ name: "Соединение восстановлено", color: "#00B23C" }));
        };

        const handleOffline = () => {
            dispatch(setAlert({ name: "Соединение прервано", color: "#F40A0A" }));
        };

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        };
    }, [dispatch]);

    if (isLoading) {
        return <div className="loading-screen">Загрузка...</div>;
    }

    return (
        <Router>
            <Routes>
                <Route path={ROUTES.HOME} element={<Main />} />
                <Route path={ROUTES.LOGIN} element={<Login />} />
                <Route path={ROUTES.GAME} element={<Game />} />
                <Route path="*" element={<Main />} />
            </Routes>
        </Router>
    );
}

export default App;